import Image from "next/image";

const QuoteImage = (props: { image: string; alt: string }) => (
  <div className="transition-1 relative h-[233px] group-hover:scale-105">
    <Image
      src={props.image}
      alt={"Klant verhalen foto " + props.alt}
      layout={"fill"}
      objectFit="cover"
    />
  </div>
);

export default QuoteImage;
